<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue">
            <i class="fas fa-cogs margin-r" aria-hidden="true"></i>Subscription
          </h3>
           <a v-if="PortalButton.submit != 'false'"  :href="Portal.url" class="button-1 w-100 btn btn-md btn-primary">Manage subscription</a>
          <!--
          <div v-if="ListSubscription.Getstatus != 'false'" class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <div class="inline">
                <div class="container">
                  <div class="row justify-content-between">
                    <div class="col-md-12">                    
                        <form ref="PlanUpgradeForm" @submit.prevent="PostUpgradePlan()" method="post" class="row" align="left">
                          <div class="col-lg-4 col-sm-12 mt-3">
                            <Multiselect v-model="MultiSelectPlans.value" v-bind="MultiSelectPlans" placeholder="Choose your plan" ref="multiselect" class="size-multiselect"></Multiselect>
                          </div>
                          <div class="col-lg-4 col-sm-12 mt-3">
                            <button :disabled="Form.submit" type="submit" class="button-1 w-100 btn btn-md btn-primary">Upgrade Subscription</button>
                          </div>
                           <div class="col-lg-4 col-sm-12 mt-3">
                            <button v-on:click="Cancel()" type="button" class="button-2 w-100 btn btn-md btn-danger">Cancel Subscription</button>
                          </div>
                        </form>
                     
                         
                    </div>
                  </div>
                </div>
              </div>
         
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr class="text-center">
                      <th scope="col">
                        <span class="text-blue">Subscription</span>
                      </th>
                      <th scope="col">
                        <span class="text-blue">Status</span>
                      </th>
                      <th scope="col">
                        <span class="text-blue">Start</span>
                      </th>
                      <th scope="col">
                        <span class="text-blue">End</span>
                      </th>
                      <th scope="col">
                        <span class="text-blue">Price</span>
                      </th>
                      <th scope="col">
                        <span class="text-blue">Invoice</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-center">
                      <td class="text-center">
                        {{ ListSubscription.name }}
                      </td>
                      <td class="text-center">
                        <p class="special-badget-status"> {{ ListSubscription.status }} </p>
                      </td>
                      <td class="text-center"> {{ ListSubscription.start }} </td>
                      <td class="text-center">{{ ListSubscription.end }} </td>
                      <td class="text-center"> {{ ListSubscription.price }} {{ ListSubscription.currency }} </td>
                      <td class="text-center">
                        <a :href="ListSubscription.pdf">Donwload Invoice</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- Billing History Table -->
      <!--
      <div v-if="ListHistory.status != 'false'" class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue">
           <i class="fa fa-history" aria-hidden="true"></i> Billing History
          </h3>
          <div  class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
            <div class="table-responsive">
                <table id="history-table" class="table table-striped responsive">
                  <thead>
                    <tr class="text-start">
                      <th scope="col">
                        <span class="text-blue">Date</span>
                      </th>
                      <th scope="col">
                        <span class="text-blue">Amount</span>
                      </th>
                      <th scope="col">
                        <span class="text-blue">Status</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="mb-special">
                    <tr class="text-start" v-if="ListHistory.status != 'false'" v-for="(obj, key, index) in ListHistory.list" :key="index">
                      <td class="text-start">{{obj.date}}</td>
                      <td class="text-start">{{obj.amount}}</td>
                      <td class="text-start">{{obj.status}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </main>
</template>

<script>
  import Api from '@/services/Request';
  import Multiselect from '@vueform/multiselect'
  import "datatables.net-dt/js/dataTables.dataTables"
  import "datatables.net-dt/css/jquery.dataTables.min.css"
  import $ from 'jquery'; 

  export default
  {
    name: 'Subscription',
    components: 
    {
      Multiselect,
    },
    data: () => (
    {
      Portal:
      {
        url : ""
      },
    
      ListSubscription: 
      {
        Getstatus:"false",
        name: "",
        start: "",
        end: "",
        status: "",
        price: "",
        currency: "",
        pdf: ""
      },
      MultiSelectPlans: 
      {
        value: [],
        closeOnSelect: true,
        options: [],
        searchable: true,
        hideSelected: true,
        showOptions: true,
        required: true,
      },
      PlanPost: 
      {
        plan_id: ""
  
      },
      ListHistory: 
      {
        status:"false",
        list: {}
      },
      Form: 
      {
        submit: false
      },
      PortalButton: 
      {
        submit: "false"
      },
    }),
    methods: 
    {
      GetSubscription() 
      {
        NProgress.start()
        Api.get("/subscription/list").then((result) => 
        {
          
          this.ListSubscription.Getstatus = result.data.status

          if(this.ListSubscription.Getstatus == "true")
          {
            NProgress.done()
            this.ListSubscription.name = result.data.subscription.name
            this.ListSubscription.start = result.data.subscription.start
            this.ListSubscription.end = result.data.subscription.end
            this.ListSubscription.status = result.data.subscription.status
            this.ListSubscription.price = result.data.subscription.price
            this.ListSubscription.currency = result.data.subscription.currency
            this.ListSubscription.pdf = result.data.subscription.pdf
          }
        })
      },
      GetUpgradePlans() 
      {
        Api.get('/subscription/list/plans').then((result) => 
        {
          if (result.data.status == "true") 
          {
            if (result.data.plans != "") 
            {
              var plans = []
              plans = result.data.plans
              let myplans = [];

              for (var i = 0; i < plans.length; i++) 
              {
                myplans.push(
                {
                  value: plans[i].plan_id,
                  label: plans[i].name
                });
              }

              this.MultiSelectPlans.options = myplans;

            }
          }
        });
      },
      PostUpgradePlan() 
      {
        this.Form.submit = true 
        this.PlanPost.plan_id = this.MultiSelectPlans.value

        Api.post("/subscription/update",this.PlanPost).then((result) => 
        {
          if(result.data.status == "true")
          {
            this.MultiSelectPlans.value = []
            this.GetSubscription()
            this.GetUpgradePlans()
            this.$moshaToast( ''+this.$filters.OnlyLetters(result.data.msg)+'', { type: 'default', position:'bottom-right', transition:'zoom' })
            this.Form.submit = false

          }
        })    
      },
      InitDatatable() 
      {
        var table = $('#history-table').DataTable( 
        {
          dom: 'lftip',
          //dom: 'Blftip',
          responsive: true,
          destroy: true,
          lengthChange: false,
          aaSorting: [ [0,'asc'] ]
        });
      },
      GetHistory() 
      {
        Api.get("/subscription/history",'').then((result) => 
        {
          this. ListHistory.status = result.data.status

          if(this.ListHistory.status == "true")
          {
            this.ListHistory.list = result.data.invoices;
            this.$nextTick(() => 
            {
              this.InitDatatable()
            })
          }
        })
      },
      Cancel() 
      {
        var answer = window.confirm("Do you wish to unsubscribe? You will not be able to undo this action. Your account will be deactivated.");
        if (answer) 
        {
          Api.get("/subscription/cancel").then((result) => 
          {
            if(result.data.status == "true")
            {
               
               this.$moshaToast( ''+this.$filters.OnlyLetters(result.data.msg)+'', { type: 'default', position:'bottom-right', transition:'zoom' })
               Api.get("/login/logout").then((result) => 
               {
                if(result.data.status == "true")
                {
                  this.$store.commit("setAuthentication", false);
                  this.$store.commit("setTypeUser", "");
                  this.$store.commit("setToken", "");
                  localStorage.clear();
                  this.$router.push({ name: "Home" });
                }
              })
              
            }
            if(result.data.status == "false")
            {
               this.$moshaToast( ''+this.$filters.OnlyLetters(result.data.msg)+'', { type: 'default', position:'bottom-right', transition:'zoom' })
            }
          })
        }
      },
      CustomerPortal() 
      {
      var currentUrl = window.location.href
      
      let Post = 
      {
        url: currentUrl
       
      };
      
        Api.post("/stripe/portal",Post).then((result) => 
        {
          if(result.data.status == "true")
          {
            this.Portal.url = result.data.url 
            this.PortalButton.submit = "true"

          }
        })    
      },
    },
    mounted()
    {
      //this.GetSubscription()
      //this.GetUpgradePlans()
      //this.GetHistory() 
      this.CustomerPortal();
    }
  }
</script>
